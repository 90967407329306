import { roundNumber } from '@/assets/js/helpers/general-helpers';

const domainRegex = require('domain-regex');
const punycode = require('punycode');
const tlds = require('tlds');

const nameSpecialChars = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿ';
// eslint-disable-next-line prefer-regex-literals
const locationNameRegexp = new RegExp('^(?=.{1,100}$)^[a-zA-Z\u00C0-\u024F\u1E02-\u1EF3\\s\\d/\\-)(`.\'\']+$');
const nameRegexp = new RegExp(`^ *(?=.{1,50}$)[a-z${nameSpecialChars}]+(?:[- ][a-z${nameSpecialChars}]+)*( )*$`, 'i');
const ssnRegexp = /^\d{11}$/;
const mobileNumberRegexp = /^\d{8}$/;
const addressRegexp = locationNameRegexp;
const zipCodeRegexp = /^\d{2,4}$/;
const cityRegexp = locationNameRegexp;

function initialUserDataValidations() {
  return {
    firstName: false,
    lastName: false,
    ssn: false,
    address: false,
    city: false,
    zipCode: false,
    email: false,
    mobileNumber: false,
    vinNumber: false,
    selectedDealer: false,
    password: false,
    confirmPassword: false,
    termsConsent: false,
    emailConfirmation: false,
  };
}

function initialCompanyInfoValidations() {
  return {
    orgNumber: false,
    companyName: false,
    companyAddress: false,
    companyZipCode: false,
    companyCity: false,
  };
}

function initialFleetReservationValidations() {
  return {
    fleetReservationQuantity: false,
  };
}

function initialDealerValidations() {
  return {
    dealerMobileNumber: false,
  };
}

function initialOrderValidations() {
  return {
    orderNumberOfCars: false,
  };
}

function initialValidations() {
  return {
    ...initialUserDataValidations(),
    ...initialCompanyInfoValidations(),
    ...initialFleetReservationValidations(),
    ...initialDealerValidations(),
    ...initialOrderValidations(),
  };
}

// @todo Should probably add some tests for this store
const ValidationStore = {
  state: initialValidations(),
  getters: {
    validations: (state) => state,
    validationErrors: (state) => Object.keys(state).filter((key) => state[key] === true),
    validationRules: (state, getters) => ({
      firstName: (value) => nameRegexp.test(value),
      lastName: (value) => nameRegexp.test(value),
      ssn: (value) => ssnRegexp.test(value),
      address: (value) => addressRegexp.test(value),
      zipCode: (value) => {
        if ('zipCodeApiStatus' in getters && getters.zipCodeApiStatus) {
          return getters.zipCodeValid && !!getters.userInfo.zipCode;
        }
        return zipCodeRegexp.test(value);
      },
      city: (value) => {
        if ('zipCodeApiStatus' in getters && getters.zipCodeApiStatus) return getters.zipCodeValid;
        return cityRegexp.test(value);
      },
      email: (value) => {
        const email = value.toLowerCase();

        // Using same email validation as EOB
        // see http://commons.apache.org/proper/commons-validator/xref/org/apache/commons/validator/routines/EmailValidator.html
        if (!/^\s*?(\S+)@(\S+?)\s*$/.test(email)) {
          return false;
        }

        const [, user, domain] = email.match(/^\s*?(.+)@(.+?)\s*$/);

        // eslint-disable-next-line no-control-regex,no-useless-escape
        if (!/^\s*(((\\.)|[^\s\x00-\x1F\x7F\(\)<>@,;:'\\\"\.\[\]]|')+|("(\\"|[^"])*"))(\.(((\\.)|[^\s\x00-\x1F\x7F\(\)<>@,;:'\\\"\.\[\]]|')+|("(\\"|[^"])*")))*$/.test(user)) {
          return false;
        }
        const validDomain = domain ? domainRegex().test(punycode.toASCII(domain)) : false;
        if (!validDomain) {
          return false;
        }

        const tld = domain.match(/.*\.(.*)/)[1];

        return tlds.indexOf(tld) !== -1;
      },
      mobileNumber: (value) => mobileNumberRegexp.test(value),
      vinNumber: (value) => /^([\w-]{11,17})$/.test(value),
      countryCode: (value) => /\b[A-Z]{2}\b/.test(value),
      carRegistrationNumber: (value) => /^.{2,60}$/.test(value),
      carMileage: (value) => /^\d+$/.test(value),
      selectedDealer: () => {
        if ('selectedDealer' in getters) return !!getters.selectedDealer;
        return true;
      },
      password: (value) => value.length > 5,
      confirmPassword: (value) => {
        if ('userCreation' in getters) return value === getters.userCreation.password;
        return true;
      },
      termsConsent: () => {
        if ('userAgreements' in getters) return !!getters.userAgreements.termsConsent;
        return true;
      },
      emailConfirmation: () => {
        if ('userAgreements' in getters) return !!getters.userAgreements.emailConfirmation;
        return true;
      },
      orgNumber: (value) => {
        if ('brregApiStatus' in getters && getters.brregApiStatus) return getters.brregOrgNumberValid && !!getters.companyInfo.orgNumber;
        return /^\d{9}$/.test(value);
      },
      companyName: (value) => {
        if ('brregApiStatus' in getters && getters.brregApiStatus) return getters.brregOrgNumberValid;
        return /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9\u0080-\u024F])[a-zA-Z0-9\u0080-\u024F '"~!-_]{2,200}$/i.test(value);
      },
      companyAddress: (value) => {
        if ('brregApiStatus' in getters && getters.brregApiStatus) return getters.brregOrgNumberValid;
        return addressRegexp.test(value);
      },
      companyZipCode: (value) => {
        if ('brregApiStatus' in getters && getters.brregApiStatus) return getters.brregOrgNumberValid;
        return zipCodeRegexp.test(value);
      },
      companyCity: (value) => {
        if ('brregApiStatus' in getters && getters.brregApiStatus) return getters.brregOrgNumberValid;
        return cityRegexp.test(value);
      },
      fleetReservationQuantity: () => {
        if (!('getFleetReservationQuantity' in getters)) return true;
        const { maxQuantity } = getters.getFleetReservationProduct()?.fleetData ?? {};
        if (!maxQuantity) return true;

        const quantity = roundNumber(getters.getFleetReservationQuantity(), 0);
        return quantity > 0 && quantity <= maxQuantity;
      },
      dealerMobileNumber: (value) => mobileNumberRegexp.test(value),
      orderNumberOfCars: () => {
        if (!('shouldShowNumberOfCars' in getters)) return true;
        if (!getters.shouldShowNumberOfCars()) return true;

        const { numberOfCars, numberOfReservations } = getters.getOpenedOrderState();
        return numberOfCars > 0 && numberOfCars <= numberOfReservations;
      },
    }),
  },
  mutations: {
    UPDATE_VALIDATIONS_ERROR(state, { key = '', error = false }) {
      this._vm.$set(state, key, error);
    },
    RESET_VALIDATIONS(state) {
      const validations = initialValidations();
      Object.keys(validations)
        .forEach((key) => {
          this._vm.$set(state, key, validations[key]);
        });
    },
    RESET_USER_DATA_VALIDATIONS(state) {
      const validations = initialUserDataValidations();
      Object.keys(validations)
        .forEach((key) => {
          this._vm.$set(state, key, validations[key]);
        });
    },
    RESET_COMPANY_INFO_VALIDATIONS(state) {
      const validations = initialCompanyInfoValidations();
      Object.keys(validations)
        .forEach((key) => {
          this._vm.$set(state, key, validations[key]);
        });
    },
  },
  actions: {
    async validate({ getters, commit }, {
      key = '', value = '', rule = key, setError = true,
    }) {
      let validation = true;
      if (getters.validationRules[rule] instanceof Function) {
        validation = getters.validationRules[rule](value);
        if (setError) commit('UPDATE_VALIDATIONS_ERROR', { key, error: !validation });
      }
      return validation;
    },
    async validateMultiple({ dispatch }, { entries = {}, setError = true }) {
      let allValid = true;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in entries) {
        if (Object.prototype.hasOwnProperty.call(entries, key)) {
          // eslint-disable-next-line no-await-in-loop
          const entryValidated = await dispatch('validate', { key, value: entries[key], setError });
          if (!entryValidated) allValid = false;
        }
      }
      return allValid;
    },
    async validateUserData({ getters, commit, dispatch }, setError = true) {
      if (setError) commit('RESET_USER_DATA_VALIDATIONS');

      const validateData = {
        firstName: getters.userInfo.firstName,
        lastName: getters.userInfo.lastName,
        address: getters.userInfo.address,
        city: getters.userInfo.city,
        zipCode: getters.userInfo.zipCode,
        email: getters.userInfo.email,
        mobileNumber: getters.userInfo.mobileNumber,
      };

      return dispatch('validateMultiple', { entries: validateData, setError });
    },
    async validateCompanyInfo({ getters, commit, dispatch }, { setError = true, basicOnly = false }) {
      if (setError) commit('RESET_COMPANY_INFO_VALIDATIONS');

      let validateData = {
        orgNumber: getters.companyInfo.orgNumber,
        companyName: getters.companyInfo.companyName,
      };

      if (!basicOnly) {
        const fullData = {
          companyAddress: getters.companyInfo.companyAddress,
          companyZipCode: getters.companyInfo.companyZipCode,
          companyCity: getters.companyInfo.companyCity,
        };

        validateData = { ...validateData, ...fullData };
      }

      return dispatch('validateMultiple', { entries: validateData, setError });
    },
    async validateUser({ getters, commit, dispatch }, {
      setError = true,
      formatData = false,
      validateTerms = false,
      validateCompany = false,
      validateDealer = true,
      ignoreNewUser = false,
    } = {}) {
      if (setError) commit('RESET_VALIDATIONS');
      if (formatData) await dispatch('formatUserInfo');

      const userValidation = await dispatch('validateUserData', setError);
      const dealerValidation = !validateDealer
        ? true
        : await dispatch('validate', { key: 'selectedDealer', setError });
      const termsValidation = !validateTerms
        ? true
        : await dispatch('validate', { key: 'termsConsent', setError });
      const companyValidation = !getters.user.isCompanyCustomer && !validateCompany
        ? true
        : await dispatch('validateCompanyInfo', { setError, basicOnly: false });
      const passwordValidation = !getters.showNewUserForm || ignoreNewUser
        ? true
        : await dispatch('validateUserCreation', setError);

      return userValidation && dealerValidation && termsValidation && passwordValidation && companyValidation;
    },
    async validateAndSendOtp({ commit, dispatch }) {
      commit('RESET_VALIDATIONS');
      const validationResponse = await dispatch('validateUserData');
      if (!validationResponse) {
        dispatch('setAlert', { key: 'formError', message: 'error.formIncomplete' });
        return false;
      }

      try {
        return await dispatch('sendCustomerOtp');
      } catch (error) {
        return false;
      }
    },
    async resetValidations({ commit }) {
      commit('RESET_VALIDATIONS');
    },
  },
};

export default ValidationStore;
