import merge from 'deepmerge';
import isEmpty from 'lodash/isEmpty';
import { CONFIGURATOR_STEPS } from '@/constants/constants-configurator';
import { bodyScroll, scrollToTopOfElement, sleep } from '@/assets/js/helpers/general-helpers';
import i18n from '@/setup/i18n-init';
import { getAdminModeIndicatorHeight } from '@/assets/js/helpers/admin-mode-helpers';

const INITIAL_MARKET_MODEL_STATE_ITEM = () => ({
  selections: [],
  selectionsOutOfStock: [],
  outdatedSelections: [],
  configurationStarted: false,
  beenInteractedWith: false,
  dynamicSteps: {
    activeStep: '',
    preventStepChange: false,
    completedSteps: [],
  },
  imageOverlayOpen: false,
});

const ConfigurationStore = {
  state: {
    marketModelStates: {},
  },
  getters: {
    getMarketModelSelectionIds: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      filterValid = true,
      skipBundled = true,
      excludeOptionTypes = [],
    } = {}) => {
      const { selections = [] } = state.marketModelStates[marketModelId] ?? {};

      let filteredSelections = skipBundled
        ? selections.filter((selectionId) => !getters.isOptionBundledInMarketModel({
          marketModelId,
          marketModelOptionId: selectionId,
        }))
        : selections;

      if (excludeOptionTypes?.length) {
        filteredSelections = selections
          .filter((selectionId) => !excludeOptionTypes.includes(getters.getMarketModelOptionById({
            marketModelId, marketModelOptionId: selectionId,
          })?.objectType));
      }

      if (!filterValid) return filteredSelections;

      return filteredSelections?.filter?.((selectionId) => getters.isMarketModelOptionValid({
        marketModelId,
        marketModelOptionId: selectionId,
        checkAdditionalOptions: true,
      })) ?? [];
    },

    getMarketModelSelectionsData: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      filterValid = true,
      skipBundled = true,
    } = {}) => getters.getMarketModelSelectionIds({ marketModelId, filterValid, skipBundled })
      .map((selectionId) => getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId: selectionId,
      })),

    getMarketModelBundledSelectionIds: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => getters.getMarketModelBundledOptions(marketModelId)
      .filter((bundledOption) => getters.isOptionSelected({
        marketModelId,
        marketModelOptionId: bundledOption.id,
      }))
      .map((bundledOption) => bundledOption.id),

    getMarketModelSelectionsOutOfStock: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelStates[marketModelId]?.selectionsOutOfStock,

    getMarketModelOutdatedSelections: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelStates[marketModelId]?.outdatedSelections,

    getMarketModelActiveDynamicStep: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelStates?.[marketModelId]?.dynamicSteps?.activeStep,

    getMarketModelPrecedingDynamicStepKeys: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      stepKey = '',
    } = {}) => {
      const modelId = getters.getMarketModelModelId(marketModelId);
      const dynamicStepKeys = getters.getModelDynamicStepsData({ modelId, filterEmpty: true })
        .map((dynamicStep) => dynamicStep.key);
      const indexOfStep = dynamicStepKeys.indexOf(stepKey);
      return dynamicStepKeys.filter((step, index) => index < indexOfStep);
    },

    getMarketModelDynamicStepPreventChange: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelStates?.[marketModelId]?.dynamicSteps?.preventStepChange,

    getMarketModelCompletedDynamicSteps: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelStates?.[marketModelId]?.dynamicSteps?.completedSteps ?? [],

    getMarketModelIncompleteCategoriesInStep: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      stepKey = getters.getModelCurrentConfigStepKey(),
    } = {}) => getters.getMarketModelCategoriesByStep({ marketModelId, stepKey })
      ?.reduce?.((incompleteCategories, category) => {
        if (!getters.isMarketModelCategoryComplete({ marketModelId, categoryId: category.id })) {
          incompleteCategories.push({
            name: category.name,
            id: category.id,
            step: category.step,
          });
        }
        return incompleteCategories;
      }, []) ?? [],

    getMarketModelIncompleteCategoriesInDynamicSteps: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      const modelId = getters.getMarketModelModelId(marketModelId);
      const dynamicSteps = getters.getModelDynamicStepsData({ modelId, filterEmptySteps: true });

      return dynamicSteps.reduce((incompleteCategories, step) => {
        incompleteCategories.push(...getters.getMarketModelIncompleteCategoriesInStep({ marketModelId, stepKey: step.key }));
        return incompleteCategories;
      }, []) ?? [];
    },

    isMarketModelOptionValid: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      checkAdditionalOptions = true,
    } = {}) => {
      const availableForCustomer = getters.isOptionAvailableForCustomerType({
        marketModelId,
        marketModelOptionId,
      });
      if (!availableForCustomer) return false;

      const availableForFinancingOption = getters.isOptionAvailableForFinancingOption({
        marketModelId,
        marketModelOptionId,
      });
      if (!availableForFinancingOption) return false;

      const availableForDealer = getters.isOptionAvailableForDealer({
        marketModelId,
        marketModelOptionId,
      });
      if (!availableForDealer) return false;

      let parentSelected = true;
      if (checkAdditionalOptions) {
        parentSelected = getters.isOptionParentSelected({
          marketModelId,
          marketModelOptionId,
        });
      }

      return parentSelected;
    },

    isMarketModelConfigurationComplete: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => getters.getMarketModelIncompleteCategoriesInDynamicSteps({ marketModelId }).length === 0,

    isValidMarketModelDynamicStep: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      stepKey = '',
    } = {}) => {
      const modelId = getters.getMarketModelModelId(marketModelId);
      const validDynamicSteps = getters.getModelDynamicStepsData({ modelId, filterEmptySteps: true })
        .map((dynamicStep) => dynamicStep.key);

      return validDynamicSteps.includes(stepKey);
    },

    isOptionRelationSelected: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      relationKey,
    } = {}) => {
      const defaultResponse = relationKey === 'prerequisite'; // Prerequisite: true, DisabledBy: false

      const validKeys = ['prerequisite', 'disabledBy'];

      if (!validKeys.includes(relationKey)) return defaultResponse;

      const relatedIds = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      })?.[relationKey] ?? [];

      if (!relatedIds?.length) return defaultResponse;

      const findSelection = (id) => getters.getMarketModelSelectionIds({ marketModelId })
        ?.find?.((selectionId) => selectionId === id);

      return relatedIds?.some?.(findSelection);
    },

    isOptionPrerequisiteSelected: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => getters.isOptionRelationSelected({ marketModelId, marketModelOptionId, relationKey: 'prerequisite' }),

    isOptionDisabledBySelected: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => getters.isOptionRelationSelected({ marketModelId, marketModelOptionId, relationKey: 'disabledBy' }),

    isOptionSelected: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => !!getters.getMarketModelSelectionIds({
      marketModelId,
      filterValid: false,
      skipBundled: false,
    })?.find?.((selectionId) => selectionId === marketModelOptionId),

    isOptionParentSelected: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const option = getters.getMarketModelOptionById({ marketModelId, marketModelOptionId });
      const additionalOptionToIds = option?.additionalOptionsData?.parents;
      if (!Array.isArray(additionalOptionToIds) || !additionalOptionToIds.length) return true;

      return additionalOptionToIds.some((parentId) => {
        const parentValid = getters.isMarketModelOptionValid({
          marketModelId,
          marketModelOptionId: parentId,
        });

        if (!parentValid) return false;

        return getters.isOptionSelected({
          marketModelId,
          marketModelOptionId: parentId,
        });
      });
    },

    getSelectedAdditionalOptions: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const mainOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      const additionalOptionIds = mainOption?.additionalOptionsData?.options;

      if (!Array.isArray(additionalOptionIds) || !additionalOptionIds.length) return null;

      const selectedAdditionalOptions = additionalOptionIds.reduce((selectedChildren, optionId) => {
        const option = getters.getMarketModelOptionById({
          marketModelId,
          marketModelOptionId: optionId,
        });

        if (getters.isOptionSelected({
          marketModelId,
          marketModelOptionId: option.id,
        })) selectedChildren.push(option);
        return selectedChildren;
      }, []);

      return selectedAdditionalOptions.length
        ? selectedAdditionalOptions
        : null;
    },

    isMarketModelDynamicStepComplete: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      stepKey = '',
    } = {}) => {
      const categories = getters.getMarketModelCategoriesByStep({ marketModelId, stepKey });

      return categories.every((category) => getters.isMarketModelCategoryComplete({
        marketModelId, categoryId: category.id,
      }));
    },

    isMarketModelCategoryComplete: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      categoryId = '',
    } = {}) => {
      const category = getters.getMarketModelCategoryById({ marketModelId, categoryId });

      if (!category) return true;

      const { required = false } = category;
      const categoryGroups = getters.getMarketModelGroupsByCategory({ marketModelId, categoryId });

      // Not required and no groups
      if (!required && !categoryGroups.length) return true;

      const selectedOptions = getters.getMarketModelSelectionsData({ marketModelId });

      if (!required) {
        // Checking group requirements
        return !categoryGroups.some((group) => {
          const groupHasSelections = !!selectedOptions
            .find((selection) => selection.id === group.id);

          return group.required && !groupHasSelections;
        });
      }

      if (required) {
        // Limitation: This will always validate the category even if groups with required exists
        // Do NOT use both groups and ungrouped items in the same category
        if (selectedOptions.find((selection) => selection.categoryId === categoryId)) {
          return true;
        }
      }

      // Nothing validated
      return false;
    },

    getMarketModelCategorySelectionCount: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      categoryId = '',
    } = {}) => getters.getMarketModelSelectionsData({ marketModelId })
      .filter((selection) => (selection.categoryId === categoryId)).length,

    getMarketModelGroupSelectionCount: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      groupId = '',
    } = {}) => getters.getMarketModelSelectionsData({ marketModelId })
      .filter((selection) => (selection.groupId === groupId)).length,

    getMarketModelDynamicStepsWithSelections: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      selectionsData = getters.getMarketModelSelectionsData({ marketModelId }),
    } = {}) => {
      const modelId = getters.getMarketModelModelId(marketModelId);
      const dynamicSteps = getters.getModelDynamicStepsData({ modelId });

      return dynamicSteps.reduce((stepsWithSelections, step) => {
        if (
          selectionsData.filter((selection) => selection.step === step.key).length > 0
        ) stepsWithSelections.push(step);
        return stepsWithSelections;
      }, []);
    },

    getMarketModelSelectedOptionsInStep: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      stepKey = '',
      selectionsData = getters.getMarketModelSelectionsData({ marketModelId }),
    }) => (
      selectionsData.length > 0
        ? selectionsData.filter((option) => option.step === stepKey)
        : []
    ),

    getMarketModelSelectedOptionsInCategory: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      categoryId = '',
      selectionsData = getters.getMarketModelSelectionsData({ marketModelId }),
      skipGrouped = true,
    } = {}) => {
      const optionFilter = (option) => (
        skipGrouped
          ? option.categoryId === categoryId && option.groupId === null
          : option.categoryId === categoryId
      );

      return selectionsData.length > 0
        ? selectionsData.filter(optionFilter)
        : [];
    },

    getMarketModelSelectedOptionsInGroup: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      groupId = '',
      selectionsData = getters.getMarketModelSelectionsData({ marketModelId }),
    } = {}) => (
      selectionsData.length > 0
        ? selectionsData.filter((item) => item.groupId === groupId)
        : []
    ),

    getMarketModelSelectionsBilledSeparately: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
    ) => getters.getMarketModelSelectionIds({ marketModelId })
      .filter(
        (selectionId) => getters.isOptionBilledSeparately({
          marketModelId,
          marketModelOptionId: selectionId,
          financingOptionType,
        }),
      ),

    getConfigurationCampaignInformation: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      financingOptionsTypesToCheck = [],
    } = {}) => {
      const campaignTexts = [];

      const marketModelCampaignText = getters.getMarketModelDiscountInformation({ marketModelId });

      if (marketModelCampaignText) campaignTexts.push(marketModelCampaignText);

      includedSelectionIds.forEach((selectionId) => {
        let isOptionDiscounted;

        if (financingOptionsTypesToCheck?.length) {
          isOptionDiscounted = financingOptionsTypesToCheck.some((financingOptionType) => getters.isMarketModelOptionDiscounted({
            marketModelId,
            marketModelOptionId: selectionId,
            financingOptionType,
          }));
        } else {
          isOptionDiscounted = getters.isMarketModelOptionDiscounted({
            marketModelId,
            marketModelOptionId: selectionId,
          });
        }

        if (!isOptionDiscounted) return;

        const campaignText = getters.getMarketModelOptionDiscountInformation({ marketModelId, marketModelOptionId: selectionId });

        if (!campaignText) return;
        campaignTexts.push(campaignText);
      });

      return campaignTexts;
    },

    getConfigurationCampaignInformationHtml: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      financingOptionsTypesToCheck = [],
      overrideTextsArray = undefined,
    } = {}) => {
      const textsArray = Array.isArray(overrideTextsArray)
        ? overrideTextsArray
        : getters.getConfigurationCampaignInformation({
          marketModelId,
          includedSelectionIds,
          financingOptionsTypesToCheck,
        });

      let informationTexts = '';

      if (!textsArray?.length) return informationTexts;

      const isMultiple = textsArray?.length > 1;

      const informationTitle = isMultiple
        ? i18n.tc('configurator.campaign.ribbon.modal.configuration.title.multiple')
        : i18n.tc('configurator.campaign.ribbon.modal.configuration.title.single');

      const informationContainerTag = isMultiple ? 'ul' : 'div';

      textsArray.forEach((text) => {
        informationTexts += isMultiple ? `<li>${text}</li>` : text;
      });

      return `<div class="configuration-campaign-information">
                <div class="information-title">${informationTitle}</div>
                <${informationContainerTag} class="information-container">
                    ${informationTexts}
                </${informationContainerTag}>
              </div>`;
    },

    hasMarketModelConfigurationStarted: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => !!state.marketModelStates[marketModelId]?.configurationStarted,

    hasMarketModelBeenInteractedWith: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => !!state.marketModelStates[marketModelId]?.beenInteractedWith,

    isMarketModelImageOverlayOpen: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => !!state.marketModelStates[marketModelId]?.imageOverlayOpen,
  },
  mutations: {
    UPDATE_MARKET_MODEL_CONFIGURATION_STATE(state, {
      marketModelId,
      marketModelState = INITIAL_MARKET_MODEL_STATE_ITEM(),
    } = {}) {
      if (!marketModelId) return;
      this._vm.$set(state.marketModelStates, marketModelId, marketModelState);
    },

    UPDATE_MARKET_MODEL_SELECTIONS(state, {
      marketModelId,
      selectionIds = INITIAL_MARKET_MODEL_STATE_ITEM().selections,
    } = {}) {
      if (!state.marketModelStates[marketModelId]) return;
      this._vm.$set(state.marketModelStates[marketModelId], 'selections', selectionIds);
    },

    REMOVE_MARKET_MODEL_SELECTION(state, {
      marketModelId,
      marketModelOptionId,
    } = {}) {
      const itemIndex = state.marketModelStates?.[marketModelId]?.selections
        ?.findIndex?.((selectionId) => selectionId === marketModelOptionId) ?? -1;
      if (itemIndex !== -1) state.marketModelStates[marketModelId].selections.splice(itemIndex, 1);
    },

    UPDATE_MARKET_MODEL_SELECTIONS_OUT_OF_STOCK(state, {
      marketModelId,
      selectionsOutOfStock = INITIAL_MARKET_MODEL_STATE_ITEM().selectionsOutOfStock,
    } = {}) {
      if (!state.marketModelStates[marketModelId]) return;
      this._vm.$set(state.marketModelStates[marketModelId], 'selectionsOutOfStock', selectionsOutOfStock);
    },

    UPDATE_MARKET_MODEL_OUTDATED_SELECTIONS(state, {
      marketModelId,
      outdatedSelections = INITIAL_MARKET_MODEL_STATE_ITEM().outdatedSelections,
    } = {}) {
      if (!state.marketModelStates[marketModelId]) return;
      this._vm.$set(state.marketModelStates[marketModelId], 'outdatedSelections', outdatedSelections);
    },

    UPDATE_MARKET_MODEL_BEEN_INTERACTED_WITH(state, {
      marketModelId,
      beenInteractedWith = INITIAL_MARKET_MODEL_STATE_ITEM().beenInteractedWith,
    } = {}) {
      if (!state.marketModelStates[marketModelId]) return;
      this._vm.$set(state.marketModelStates[marketModelId], 'beenInteractedWith', beenInteractedWith);
    },

    UPDATE_MARKET_MODEL_CONFIGURATION_STARTED(state, {
      marketModelId,
      configurationStarted = INITIAL_MARKET_MODEL_STATE_ITEM().configurationStarted,
    } = {}) {
      if (!state.marketModelStates[marketModelId]) return;
      this._vm.$set(state.marketModelStates[marketModelId], 'configurationStarted', configurationStarted);
    },

    UPDATE_MARKET_MODEL_DYNAMIC_STEPS_ACTIVE_STEP(state, {
      marketModelId,
      stepKey = INITIAL_MARKET_MODEL_STATE_ITEM().dynamicSteps.activeStep,
    } = {}) {
      if (!state.marketModelStates[marketModelId]) return;
      this._vm.$set(state.marketModelStates[marketModelId].dynamicSteps, 'activeStep', stepKey);
    },

    UPDATE_MARKET_MODEL_DYNAMIC_STEPS_PREVENT_CHANGE(state, {
      marketModelId,
      preventStepChange = INITIAL_MARKET_MODEL_STATE_ITEM().dynamicSteps.preventStepChange,
    } = {}) {
      if (!state.marketModelStates[marketModelId]) return;
      this._vm.$set(state.marketModelStates[marketModelId].dynamicSteps, 'preventStepChange', preventStepChange);
    },

    ADD_MARKET_MODEL_COMPLETED_DYNAMIC_STEP(state, {
      marketModelId,
      stepKey = '',
    } = {}) {
      if (!state.marketModelStates[marketModelId]) return;
      if (!stepKey) return;

      const existingIndex = state.marketModelStates?.[marketModelId]?.dynamicSteps?.completedSteps
        .findIndex((completedStepKey) => completedStepKey === stepKey);

      if (existingIndex === -1) {
        state.marketModelStates[marketModelId].dynamicSteps.completedSteps.push(stepKey);
      }
    },

    REMOVE_MARKET_MODEL_COMPLETED_DYNAMIC_STEP(state, {
      marketModelId,
      stepKey = '',
    } = {}) {
      if (!state.marketModelStates[marketModelId]) return;
      const stepIndex = state.marketModelStates?.[marketModelId]?.dynamicSteps?.completedSteps
        ?.findIndex?.((completedStepKey) => completedStepKey === stepKey) ?? -1;
      if (stepIndex !== -1) state.marketModelStates[marketModelId].dynamicSteps.completedSteps.splice(stepIndex, 1);
    },

    UPDATE_MARKET_MODEL_IMAGE_OVERLAY_OPEN(state, {
      marketModelId,
      imageOverlayOpen = INITIAL_MARKET_MODEL_STATE_ITEM().imageOverlayOpen,
    } = {}) {
      if (!state.marketModelStates[marketModelId]) return;
      this._vm.$set(state.marketModelStates[marketModelId], 'imageOverlayOpen', imageOverlayOpen);
    },

  },
  actions: {
    async initMarketModelConfigurationState({ state, commit }, {
      marketModelId,
      force = false,
    } = {}) {
      // @todo Could this do more useful things?
      if (!state.marketModelStates[marketModelId] || force) {
        commit('UPDATE_MARKET_MODEL_CONFIGURATION_STATE', { marketModelId });
      }
    },

    async selectMarketModelOption({
      commit, getters, dispatch,
    }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      updateSelectionBasedData = true,
      updateConfiguratorKey = true,
      trackSelection = false,
    } = {}) {
      // @todo @refactor This horrible mess needs to be cleaned up
      const item = getters.getMarketModelOptionById({ marketModelId, marketModelOptionId });

      if (isEmpty(item)) return;
      if (!getters.isOptionInStock({ marketModelId, marketModelOptionId })) return;

      if (getters.isOptionRequiredForFinancingOption({ marketModelId, marketModelOptionId })) {
        if (getters.isOptionSelected({ marketModelId, marketModelOptionId })) return;
      }

      const prerequisiteSelected = getters.isOptionPrerequisiteSelected({
        marketModelId,
        marketModelOptionId,
      });
      const disabledBySelected = getters.isOptionDisabledBySelected({
        marketModelId,
        marketModelOptionId,
      });

      if (!prerequisiteSelected || disabledBySelected) return;

      const categoryId = parseInt(item.categoryId, 10);
      const groupId = parseInt(item.groupId, 10);
      const itemId = parseInt(item.id, 10);

      const category = getters.getMarketModelCategoryById({ marketModelId, categoryId });
      const group = getters.getMarketModelGroupById({ marketModelId, groupId });

      const {
        singleSelection: groupSingleSelection = false,
        required: groupRequired = false,
      } = group ?? {};

      const {
        singleSelection: categorySingleSelection = false,
        required: categoryRequired = false,
      } = category ?? {};

      const mergedSelections = merge(
        getters.getMarketModelSelectionsData({
          marketModelId,
          filterValid: false,
          skipBundled: false,
        }),
        [],
      );

      const itemInArray = mergedSelections.find((x) => (x.id === itemId));

      let removeItem = false;
      let addItem = false;
      let itemsToRemove = [];

      if (itemInArray !== undefined) {
        const itemIndex = mergedSelections.indexOf(itemInArray);
        const hasCategorySelections = getters.getMarketModelCategorySelectionCount({ marketModelId, categoryId }) > 1;
        const hasGroupSelections = getters.getMarketModelGroupSelectionCount({ marketModelId, groupId }) > 1;

        if (groupId) {
          if (categoryRequired) {
            if (groupRequired) {
              if (hasCategorySelections && hasGroupSelections) {
                removeItem = true;
              }
            } else if (hasCategorySelections) {
              removeItem = true;
            }
          } else if (groupRequired) {
            if (hasGroupSelections) {
              removeItem = true;
            }
          } else {
            removeItem = true;
          }
        } else if (!groupId) {
          if (categoryRequired) {
            if (hasCategorySelections) {
              removeItem = true;
            }
          } else {
            removeItem = true;
          }
        }

        // @todo StockCars Dirty fix for included selections...
        if (removeItem && !item.includedInStockCar) {
          mergedSelections.splice(itemIndex, 1);
        }
      } else {
        if (groupId && groupSingleSelection) {
          itemsToRemove = mergedSelections.filter((x) => (x.groupId === groupId));
          if (itemsToRemove) {
            itemsToRemove.forEach((itemToRemove) => {
              mergedSelections.splice(mergedSelections.indexOf(itemToRemove), 1);
            });
          }
        } else if (categorySingleSelection) {
          itemsToRemove = mergedSelections.filter((x) => (x.categoryId === categoryId));

          if (itemsToRemove) {
            itemsToRemove.forEach((itemToRemove) => {
              mergedSelections.splice(mergedSelections.indexOf(itemToRemove), 1);
            });
          }
        }

        addItem = true;
        mergedSelections.push(item);
      }

      const mergedSelectionIds = mergedSelections
        .map((mergedSelection) => mergedSelection.id)
        .filter((mergedSelectionId) => !!mergedSelectionId);

      commit('UPDATE_MARKET_MODEL_SELECTIONS', { marketModelId, selectionIds: mergedSelectionIds });

      if (!addItem && !removeItem) return;

      if (updateConfiguratorKey) await dispatch('setConfiguratorKeyParam');

      if (trackSelection) {
        if (addItem) {
          this._vm.$tracking.datadog.configuratorOptionSelected(item);
          this._vm.$tracking.adobe.configuratorAddOption(item);
        }
        if (removeItem) this._vm.$tracking.adobe.configuratorRemoveOption(item);

        itemsToRemove.forEach((itemToRemove) => {
          this._vm.$tracking.adobe.configuratorRemoveOption(itemToRemove);
        });

        await dispatch('setMarketModelFirstInteraction');
      }

      await dispatch('checkStepOptionsRelations', { marketModelId, stepKey: item.step });

      if (updateSelectionBasedData) {
        await dispatch('setMarketModelDynamicStepCompletedState', { marketModelId, stepKey: item.step });

        try {
          dispatch('fetchAndSetSelectionBasedData', { marketModelId });
        } catch (error) {
          // Do nothing..
        }
      }
    },

    checkStepOptionsRelations({ getters, commit }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      stepKey = getters.getModelCurrentConfigStepKey(getters.getMarketModelModelId(marketModelId)),
    } = {}) {
      const stepSelections = getters.getMarketModelSelectionsData({ marketModelId })
        .filter((x) => (x.step === stepKey));

      stepSelections.forEach((stepSelection) => {
        const prerequisiteSelected = getters.isOptionPrerequisiteSelected({
          marketModelId,
          marketModelOptionId: stepSelection.id,
        });
        const disabledBySelected = getters.isOptionDisabledBySelected({
          marketModelId,
          marketModelOptionId: stepSelection.id,
        });
        if (!prerequisiteSelected || disabledBySelected) {
          commit('REMOVE_MARKET_MODEL_SELECTION', { marketModelId, marketModelOptionId: stepSelection.id });
        }
      });
    },

    async preselectMarketModelStandardOptions({ getters, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) {
      const mmOptionsToPreselect = [
        ...getters.getMarketModelStandardOptions({ marketModelId, includeAdditionalOptions: true }),
        ...getters.getMarketModelBundledOptions(marketModelId),
      ];

      const optionsWithPrerequisites = [];

      const preselectOptionsPromises = mmOptionsToPreselect.map(async (option) => {
        isEmpty(option.prerequisite)
          ? optionsWithPrerequisites.push(option)
          : await dispatch('selectMarketModelOption', {
            marketModelId,
            marketModelOptionId: option.id,
            updateConfiguratorKey: false,
          });
      });

      await Promise.allSettled(preselectOptionsPromises);

      const optionsWithPrerequisitesPromises = optionsWithPrerequisites.map(async (option) => {
        await dispatch('selectMarketModelOption', {
          marketModelId,
          marketModelOptionId: option.id,
          updateSelectionBasedData: false,
          updateConfiguratorKey: false,
        });
      });

      await Promise.allSettled(optionsWithPrerequisitesPromises);
    },

    async resetMarketModelSelections({ getters, commit, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) {
      commit('UPDATE_MARKET_MODEL_SELECTIONS', { marketModelId, selectionIds: [] });
      await dispatch('preselectMarketModelStandardOptions', { marketModelId });
    },

    checkMarketModelOutOfStockSelections({ getters, commit, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) {
      const modelId = getters.getMarketModelModelId(marketModelId);

      if (!getters.getModelUsesInventory(modelId)) return;

      const selectionsOutOfStock = getters.getMarketModelSelectionsData({ marketModelId })
        .reduce((outOfStockList, selection) => {
          if (
            !getters.isOptionInStock({ marketModelId, marketModelOptionId: selection.id })
          || getters.selectionsOutOfStockWithOption({
            marketModelId,
            marketModelOptionId: selection.id,
          }).length > 0
          ) {
            outOfStockList.push(selection.id);
            commit('REMOVE_MARKET_MODEL_SELECTION', { marketModelId, marketModelOptionId: selection.id });
            dispatch('validateModelConfigStep', { modelId, stepKey: selection.step });
            dispatch('checkStepOptionsRelations', { marketModelId, stepKey: selection.step });
            dispatch('setLoader', false);
          }

          return outOfStockList;
        }, []);

      if (!selectionsOutOfStock.length) return;

      commit('UPDATE_MARKET_MODEL_SELECTIONS_OUT_OF_STOCK', { marketModelId, selectionsOutOfStock });
    },

    setMarketModelConfigurationStarted({ getters, commit }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) {
      if (getters.hasMarketModelConfigurationStarted(marketModelId)) return;

      commit('UPDATE_MARKET_MODEL_CONFIGURATION_STARTED', { marketModelId, configurationStarted: true });
      this._vm.$tracking.adobe.configuratorLineSelected();
    },

    setMarketModelFirstInteraction({ getters, commit }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) {
      if (getters.hasMarketModelBeenInteractedWith(marketModelId)) return;

      commit('UPDATE_MARKET_MODEL_BEEN_INTERACTED_WITH', { marketModelId, beenInteractedWith: true });
      this._vm.$tracking.adobe.configuratorFirstInteraction();
    },

    async validateMarketModelConfiguration({ getters, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) {
      const modelId = getters.getMarketModelModelId(marketModelId);

      if (!modelId) return;
      if (marketModelId !== getters.getModelSelectedMarketModelId()) return;

      const dynamicSteps = getters.getModelDynamicStepsData({ modelId, filterEmptySteps: true });

      const validateSelectionsPromises = dynamicSteps.map(async (step) => {
        await dispatch('checkStepOptionsRelations', {
          marketModelId: getters.getModelSelectedMarketModelId(modelId), stepKey: step.key,
        });
        await dispatch('setMarketModelDynamicStepCompletedState', {
          marketModelId: getters.getModelSelectedMarketModelId(modelId), stepKey: step.key,
        });
      });

      await Promise.all(validateSelectionsPromises);

      const targetStep = getters.isMarketModelConfigurationComplete({ marketModelId })
        ? CONFIGURATOR_STEPS.financing
        : CONFIGURATOR_STEPS.configuration;

      await dispatch('completeModelPrecedingConfigSteps', { modelId, stepKey: targetStep });
    },

    async setMarketModelActiveDynamicStep({ getters, commit }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      stepKey = '',
    } = {}) {
      if (getters.getMarketModelDynamicStepPreventChange(marketModelId)) return;
      if (!getters.isValidMarketModelDynamicStep({ marketModelId, stepKey })) return;
      commit('UPDATE_MARKET_MODEL_DYNAMIC_STEPS_ACTIVE_STEP', { marketModelId, stepKey });
    },

    async setMarketModelDynamicStepPreventChange({ getters, commit }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      preventStepChange = INITIAL_MARKET_MODEL_STATE_ITEM().dynamicSteps.preventStepChange,
    } = {}) {
      commit('UPDATE_MARKET_MODEL_DYNAMIC_STEPS_PREVENT_CHANGE', { marketModelId, preventStepChange });
    },

    async setMarketModelDynamicStepCompletedState({ getters, commit }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      stepKey = '',
      forceIncomplete = false,
    } = {}) {
      if (!getters.isValidMarketModelDynamicStep({ marketModelId, stepKey })) return;

      const validated = getters.isMarketModelDynamicStepComplete({ marketModelId, stepKey });

      forceIncomplete || !validated
        ? commit('REMOVE_MARKET_MODEL_COMPLETED_DYNAMIC_STEP', { marketModelId, stepKey })
        : commit('ADD_MARKET_MODEL_COMPLETED_DYNAMIC_STEP', { marketModelId, stepKey });
    },

    async setMarketModelImageOverlayOpen({ getters, commit, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      imageOverlayOpen = false,
      userInteraction = false,
    } = {}) {
      if (userInteraction && !getters.hasMarketModelBeenInteractedWith(marketModelId)) {
        await dispatch('setMarketModelFirstInteraction', { marketModelId });
      }

      if (!userInteraction && getters.hasMarketModelBeenInteractedWith(marketModelId)) return;

      commit('UPDATE_MARKET_MODEL_IMAGE_OVERLAY_OPEN', { marketModelId, imageOverlayOpen });
    },

    async completeMarketModelPrecedingDynamicSteps({ getters, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      stepKey = getters.getMarketModelActiveDynamicStep(marketModelId),
    } = {}) {
      const precedingStepsKeys = getters.getMarketModelPrecedingDynamicStepKeys({ marketModelId, stepKey });

      const completePrecedingPromises = precedingStepsKeys.map(async (precedingStepKey) => {
        await dispatch('setMarketModelDynamicStepCompletedState', { marketModelId, stepKey: precedingStepKey });
      });

      await Promise.all(completePrecedingPromises);
    },

    async scrollToDynamicStepOrCategory({ getters, dispatch }, { stepKey, categoryId }) {
      await dispatch('setMarketModelDynamicStepPreventChange', {
        preventStepChange: false,
      });
      bodyScroll.enable();
      const isFirstStep = getters.getModelDynamicStepsData({ filterEmptySteps: true })?.[0].key === stepKey;

      let targetContainer;

      if (isFirstStep) {
        targetContainer = document.querySelector('[data-target-configurator-scroll-top]');
      } else {
        targetContainer = stepKey
          ? document.querySelector(`[data-dynamic-step-container="${stepKey}"]`)
          : document.querySelector(`[data-category-id="${categoryId}"]`);
      }

      if (!targetContainer) return;

      if (stepKey) {
        await dispatch('setMarketModelActiveDynamicStep', { stepKey });
        await dispatch('setMarketModelDynamicStepCompletedState', { stepKey });
        await dispatch('completeMarketModelPrecedingDynamicSteps', { stepKey });
      }

      await dispatch('setMarketModelDynamicStepPreventChange', {
        preventStepChange: true,
      });

      const topAreaHeight = document.querySelector('[data-target-configuration-sticky-top]')?.offsetHeight ?? 0;

      const parentStyle = window.getComputedStyle(targetContainer.parentElement);
      const extraOffset = parseInt(parentStyle.getPropertyValue('padding-top'), 10);
      const adminModeIndicatorHeight = getAdminModeIndicatorHeight();

      const scrollOffset = -(topAreaHeight + extraOffset + adminModeIndicatorHeight);

      await scrollToTopOfElement(targetContainer, scrollOffset);

      await sleep(0);
      await dispatch('setMarketModelDynamicStepPreventChange', {
        preventStepChange: false,
      });
    },
  },
};

export default ConfigurationStore;
