import axios from 'axios';
import httpInternal from '@/http/httpInternal';
import consoleLog from '@/assets/js/helpers/console-log';
import { createWltpValuesItem } from '@/assets/js/helpers/wltp-helpers';
import isObject from 'lodash/isObject';

const INITIAL_WLTP_REQUEST_ITEM = () => ({
  id: '',
  abortController: null,
});

const INITIAL_WLTP_STATE_ITEM = () => ({
  dynamicValues: {
    ...createWltpValuesItem(),
  },
  standardValues: {
    ...createWltpValuesItem(),
  },
  status: {
    request: INITIAL_WLTP_REQUEST_ITEM(),
    error: false,
    loading: false,
  },
});

const WltpStore = {
  state: {
    wltpStates: {},
  },
  getters: {
    getWltpState: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.wltpStates[marketModelId] ?? {},

    getWltpValues: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      useStandard = false,
    } = {}) => {
      const hasDynamicWltp = !!state.wltpStates[marketModelId]?.dynamicValues?.wltp?.length;

      return useStandard || !hasDynamicWltp
        ? state.wltpStates[marketModelId]?.standardValues
        : state.wltpStates[marketModelId]?.dynamicValues;
    },
  },
  mutations: {
    UPDATE_WLTP_STATE(state, {
      marketModelId = '',
      wltpState = INITIAL_WLTP_STATE_ITEM(),
    } = {}) {
      this._vm.$set(
        state.wltpStates,
        marketModelId,
        wltpState,
      );
    },

    UPDATE_WLTP_STANDARD_VALUES(state, {
      marketModelId = '',
      wltpValues = createWltpValuesItem(),
    } = {}) {
      if (!state?.wltpStates?.[marketModelId]) return;
      this._vm.$set(state.wltpStates[marketModelId], 'standardValues', wltpValues);
    },

    UPDATE_WLTP_DYNAMIC_VALUES(state, {
      marketModelId = '',
      wltpValues = createWltpValuesItem(),
    } = {}) {
      if (!state?.wltpStates?.[marketModelId]) return;
      this._vm.$set(state.wltpStates[marketModelId], 'dynamicValues', wltpValues);
    },

    UPDATE_WLTP_REQUEST(state, {
      marketModelId = '',
      request = INITIAL_WLTP_REQUEST_ITEM(),
    } = {}) {
      if (!state?.wltpStates?.[marketModelId]) return;
      this._vm.$set(state.wltpStates[marketModelId].status, 'request', request);
    },

    UPDATE_WLTP_LOADING(state, {
      marketModelId = '',
      loading = false,
    } = {}) {
      if (!state?.wltpStates?.[marketModelId]) return;
      this._vm.$set(state.wltpStates[marketModelId].status, 'loading', loading);
    },

    UPDATE_WLTP_ERROR(state, {
      marketModelId = '',
      error = false,
    } = {}) {
      if (!state?.wltpStates?.[marketModelId]) return;
      this._vm.$set(state.wltpStates[marketModelId].status, 'error', error);
    },
  },
  actions: {
    async initWltpState({ getters, commit, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      skipWltpData = true,
      standardWltpValues = null,
    } = {}) {
      commit('UPDATE_WLTP_STATE', { marketModelId });

      const standardWltp = isObject(standardWltpValues)
        ? standardWltpValues
        : getters.getMarketModelById(marketModelId)?.wltpData ?? {};

      await dispatch('setWltpValues', {
        marketModelId,
        wltpValues: standardWltp,
        isStandard: true,
      });

      if (!skipWltpData) {
        await dispatch('fetchAndSetWltpData', { marketModelId });
      }
    },

    async setWltpValues({ getters, commit }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      wltpValues = createWltpValuesItem(),
      isStandard = false,
    } = {}) {
      const committer = isStandard
        ? 'UPDATE_WLTP_STANDARD_VALUES'
        : 'UPDATE_WLTP_DYNAMIC_VALUES';

      commit(committer, { marketModelId, wltpValues });
    },

    async fetchAndSetWltpData({
      state, getters, commit, dispatch,
    }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) {
      const setLoadingState = (loading = false) => commit('UPDATE_WLTP_LOADING', { marketModelId, loading });
      const setErrorState = (error = false) => commit('UPDATE_WLTP_ERROR', { marketModelId, error });

      if (!state?.wltpStates?.[marketModelId]) await dispatch('initWltpState', { marketModelId, skipWltpData: true });

      try {
        const { request, loading } = getters.getWltpState(marketModelId)?.status ?? {};

        const selectionsKey = JSON.stringify(
          getters.getMarketModelSelectionIds({
            marketModelId,
            skipBundled: false,
          })
            ?.map((selectionId) => ({ id: selectionId })),
        );

        if (request?.id === selectionsKey) return;

        request?.abortController?.abort
          ? request.abortController.abort()
          : setLoadingState(false);

        const abortController = new AbortController();

        commit('UPDATE_WLTP_REQUEST', {
          marketModelId,
          request: {
            id: selectionsKey,
            abortController,
          },
        });

        if (!loading) setLoadingState(true);

        const wltpResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetWltpDataUrl,
          signal: abortController.signal,
          params: {
            marketModelId,
            selections: selectionsKey,
          },
          timeout: 3000,
        });

        const { data: responseData } = wltpResponse;

        const wltpValues = createWltpValuesItem(responseData);

        await dispatch('setWltpValues', { marketModelId, wltpValues, isStandard: false });

        setTimeout(() => {
          setLoadingState(false);
        }, 0);
      } catch (error) {
        await dispatch('setWltpValues', { marketModelId, isStandard: false });
        if (axios.isCancel(error)) return;

        setLoadingState(false);
        setErrorState(true);
        consoleLog(error);
      }
    },
  },
};

export default WltpStore;
