import i18n from '@/setup/i18n-init';
import consoleLog from '@/assets/js/helpers/console-log';
import {
  CUSTOMER_TYPES,
  FINANCING_OPTIONS,
  LEASING_KEYS,
  LEASING_SETTINGS,
  LOAN_KEYS,
  LOAN_SETTINGS,
} from '@/constants/constants-financing';
import {
  calculateEffectiveRate,
  calculateLeasingAnnualServiceMonthlyCost,
  calculateLeasingMonthlyCost,
  calculateLoanMonthlyCost,
  createLeasingDataParams,
  // @todo OLD ELECTRIC VAT CALCULATION
  // VAT_CORRECTION_FACTORS_PER_YEAR,
} from '@/assets/js/helpers/financing-helpers';
import { roundNumber } from '@/assets/js/helpers/general-helpers';
import { parseLeasingVariables, parseLoanVariables } from '@/assets/js/helpers/cms-helpers';

const createFinancingOption = (optionData) => ({
  id: null,
  type: FINANCING_OPTIONS.cash,
  paymentType: null,
  customerTypes: [CUSTOMER_TYPES.consumer],
  content: {},
  linkTypeContent: null,
  financialService: null,
  monthly: false,
  cashClone: false,
  isLink: false,
  ...optionData,
});

const DEFAULT_OPTION_CASH = createFinancingOption({
  type: FINANCING_OPTIONS.cash,
});

const DEFAULT_OPTION_LEASING = createFinancingOption({
  type: FINANCING_OPTIONS.leasing,
  monthly: true,
});

const DEFAULT_OPTION_LOAN = createFinancingOption({
  type: FINANCING_OPTIONS.loan,
  monthly: true,
});

const DEFAULT_OPTION_OFFER = createFinancingOption({
  type: FINANCING_OPTIONS.offer,
  cashClone: true,
});

const DEFAULT_OPTION_LINK = createFinancingOption({
  type: FINANCING_OPTIONS.link,
});

const DEFAULT_FINANCING_OPTIONS = [
  DEFAULT_OPTION_CASH,
  DEFAULT_OPTION_LEASING,
  DEFAULT_OPTION_LOAN,
  DEFAULT_OPTION_OFFER,
  DEFAULT_OPTION_LINK,
];

export const DEFAULT_SETTINGS_LOAN = () => ({
  [LOAN_SETTINGS.downPayment]: null,
  [LOAN_SETTINGS.paymentPeriod]: null,
});

export const DEFAULT_SETTINGS_LEASING = () => ({
  [LEASING_SETTINGS.annualMileage]: null,
  [LEASING_SETTINGS.advanceRent]: null,
  [LEASING_SETTINGS.leasePeriod]: null,
});

const INITIAL_AUTO_SELECTED_OPTIONS_DATA = () => ({
  financingOptionType: null,
  marketModelId: null,
  items: [],
});

const INITIAL_FINANCING_STATE_ITEM = () => ({
  selectedFinancingOption: '',
  selectedCustomerType: CUSTOMER_TYPES.consumer,
  availableFinancingOptions: [],
  financingSettings: {
    calculating: false,
    [FINANCING_OPTIONS.loan]: DEFAULT_SETTINGS_LOAN(),
    [FINANCING_OPTIONS.leasing]: DEFAULT_SETTINGS_LEASING(),
  },
  autoSelectedOptionsData: INITIAL_AUTO_SELECTED_OPTIONS_DATA(),
});

const FinancingStore = {
  state: {
    financingStates: {},
  },
  getters: {
    getFinancingState: (state) => (financingStateKey) => state.financingStates[financingStateKey],

    getFinancingSettings: (state, getters) => (
      financingStateKey = getters.getModelSelectedMarketModelId(),
    ) => getters.getFinancingState(financingStateKey)?.financingSettings,

    isFinancingSettingsCalculating: (state, getters) => (
      financingStateKey = getters.getModelSelectedMarketModelId(),
    ) => getters.getFinancingSettings(financingStateKey)?.calculating,

    getSelectedFinancingOption: (state, getters) => ({
      financingStateKey = getters.getActiveModelId,
      allowCashClones = false,
    } = {}) => {
      const { selectedFinancingOption } = state.financingStates[financingStateKey] ?? {};

      if (allowCashClones) return selectedFinancingOption;

      const { cashClone: isClone } = getters.getFinancingOptionData({
        financingStateKey,
        financingOptionType: selectedFinancingOption,
      }) ?? {};

      return isClone
        ? FINANCING_OPTIONS.cash
        : selectedFinancingOption;
    },

    getAvailableFinancingOptions: (state, getters) => ({
      financingStateKey = getters.getActiveModelId,
      allowCashClones = false,
      allowLinks = false,
    } = {}) => {
      const { availableFinancingOptions = [] } = state.financingStates[financingStateKey] ?? {};

      let filteredFinancingOptions = availableFinancingOptions
        .filter((financingOption) => financingOption?.customerTypes
          ?.includes(getters.getFinancingCustomerType(financingStateKey)));

      if (!allowCashClones) {
        filteredFinancingOptions = filteredFinancingOptions.filter((option) => !option.cashClone);
      }

      if (!allowLinks) {
        filteredFinancingOptions = filteredFinancingOptions.filter((option) => !option.isLink);
      }

      return filteredFinancingOptions;
    },

    getAvailableLinkedFinancingOptions: (state, getters) => ({
      financingStateKey = getters.getActiveModelId,
    } = {}) => {
      const availableFinancingOptions = getters.getAvailableFinancingOptions({
        financingStateKey,
        allowLinks: true,
      });

      return availableFinancingOptions.filter((option) => option.isLink);
    },

    getFinancingOptionData: (state, getters) => ({
      financingStateKey = getters.getActiveModelId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey,
        allowCashClones: true,
      }),
      financingOptionId = undefined,
    } = {}) => {
      const availableOptions = getters.getAvailableFinancingOptions({
        financingStateKey,
        allowCashClones: true,
        allowLinks: true,
      });

      if (financingOptionId) return availableOptions.find((availableOption) => availableOption.id === financingOptionId);

      return availableOptions.find((availableOption) => availableOption.type === financingOptionType);
    },

    getFinancingOptionExtraSteps: (state, getters) => ({
      financingStateKey = getters.getActiveModelId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey,
        allowCashClones: true,
      }),
    } = {}) => {
      const stepData = getters.getFinancingOptionData({
        financingStateKey,
        financingOptionType,
      });
      if (!stepData) return [];

      const { extraSteps } = stepData;
      return Array.isArray(extraSteps) ? extraSteps : [];
    },

    getFinancingCustomerType: (state, getters) => (
      financingStateKey = getters.getActiveModelId,
    ) => getters.getFinancingState(financingStateKey)?.selectedCustomerType ?? CUSTOMER_TYPES.consumer,

    isFinancingCustomerTypeCompany: (state, getters) => (
      financingStateKey = getters.getActiveModelId,
    ) => getters.getFinancingCustomerType(financingStateKey) === CUSTOMER_TYPES.company,

    getAvailableFinancingCustomerTypes: (state, getters) => (
      financingStateKey = getters.getActiveModelId,
    ) => {
      // @todo Temporary PreReservation active override
      if (
        getters.userHasPreReservation
        && getters.getModelPreReservationRequired(financingStateKey)
      ) {
        return [CUSTOMER_TYPES.consumer];
      }

      const { availableFinancingOptions = [] } = state.financingStates[financingStateKey] ?? {};

      const availableCustomerTypes = availableFinancingOptions.reduce((availableTypes, financingOption) => {
        const { customerTypes: optionTypes = [] } = financingOption ?? {};

        optionTypes.forEach((type) => {
          if (!availableTypes.includes(type)) availableTypes.push(type);
        });

        return availableTypes;
      }, []);

      if (!availableCustomerTypes.length) availableCustomerTypes.push(CUSTOMER_TYPES.consumer);

      availableCustomerTypes.sort((a, b) => {
        const sortOrder = Object.values(CUSTOMER_TYPES);
        return sortOrder.indexOf(a) - sortOrder.indexOf(b);
      });

      return availableCustomerTypes;
    },

    isFinancingCompany: (state, getters) => (
      financingStateKey = getters.getActiveModelId,
    ) => {
      const isCommercialVehicle = getters.getModelById(financingStateKey)?.commercialVehicle;
      const isCustomerCompany = getters.isFinancingCustomerTypeCompany(financingStateKey);

      return isCommercialVehicle && isCustomerCompany;
    },

    isValidFinancingOption: (state, getters) => ({
      financingStateKey = getters.getActiveModelId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey,
        allowCashClones: true,
      }),
    } = {}) => {
      if (financingOptionType === FINANCING_OPTIONS.cash) return true;

      return !!getters.getAvailableFinancingOptions({
        financingStateKey,
        allowCashClones: true,
      }).find((availableOption) => availableOption.type === financingOptionType);
    },

    isMonthlyFinancingOption: (state, getters) => ({
      financingStateKey = getters.getActiveModelId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey,
        allowCashClones: true,
      }),
    } = {}) => {
      const financingOption = getters.getFinancingOptionData({
        financingStateKey,
        financingOptionType,
      });

      return !!financingOption?.monthly;
    },

    isOfferFinancingOption: (state, getters) => ({
      financingStateKey = getters.getActiveModelId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey,
        allowCashClones: true,
      }),
    } = {}) => {
      const financingOption = getters.getFinancingOptionData({
        financingStateKey,
        financingOptionType,
      });

      return !!financingOption?.isOffer;
    },

    getCurrencyCode: (state, getters) => ({
      financingStateKey = getters.getActiveModelId,
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey,
        allowCashClones: true,
      }),
      addAsterisk = true,
      forceCashCurrencyCode = false,
    } = {}) => {
      if (!getters.isValidFinancingOption({ financingStateKey, financingOptionType })) return i18n.tc('general.currencyShort');

      const asterisk = addAsterisk
        ? getters.getFinancingAsterisk({ financingStateKey, financingOptionType, marketModelId })
        : '';

      return getters.isMonthlyFinancingOption({ financingStateKey, financingOptionType })
      && !forceCashCurrencyCode
        ? `${i18n.tc('general.currencyMonthly')}${asterisk}`
        : `${i18n.tc('general.currencyShort')}${asterisk}`;
    },

    shouldUseLeasingVatCorrection: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => !getters.isMarketModelElectric({ marketModelId }),

    getFinancingSuffixes: (state, getters) => ({
      year: i18n.tc('general.years'),
      distance: 'km',
      percent: '%',
      currency: getters.getCurrencyCode({
        financingOptionType: FINANCING_OPTIONS.cash,
        addAsterisk: false,
      }),
    }),

    getFinancingAsterisk: (state, getters) => ({
      financingStateKey = getters.getActiveModelId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey,
        allowCashClones: true,
      }),
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      if (!getters.isValidFinancingOption({
        financingStateKey,
        financingOptionType,
      })) return undefined;

      const financingOptionsWithDisclaimers = getters.getAvailableFinancingOptions({
        financingStateKey,
      }).reduce((optionsWithDisclaimer, option) => {
        const disclaimer = getters.getMarketModelPriceDisclaimer({
          marketModelId,
          financingOptionType: option.type,
          includedSelectionIds: null,
          addAsterisk: false,
          ignoreDiscount: false,
          checkOnly: true,
        });
        if (disclaimer) optionsWithDisclaimer.push(option.type);
        return optionsWithDisclaimer;
      }, []);

      const asterisk = '*';
      const optionIndex = financingOptionsWithDisclaimers.indexOf(financingOptionType);

      return asterisk.repeat(optionIndex + 1);
    },

    getLeasingSettings: (state, getters) => ({
      financingStateKey = getters.getModelSelectedMarketModelId(),
      financingConfig = getters.getConfigByKeyString('financing.config'),
      useDefaultFinancingSettings = false,
    } = {}) => {
      const leasingConfig = financingConfig?.[FINANCING_OPTIONS.leasing] ?? {};

      let {
        defaultAdvanceRent: advanceRentPercent = 0,
        defaultLeasePeriod: leasePeriodYears = 0,
      } = leasingConfig;

      const {
        establishmentFee = 0,
        perTermFee = 0,
        nominalInterestRate = 0,
        annualMileages = [],
      } = leasingConfig;

      const annualMileagesData = Array.isArray(annualMileages) ? annualMileages : [];

      let mileageObj = annualMileagesData.find((item) => item.default) || {};

      if (!useDefaultFinancingSettings) {
        const { leasing: leasingSettings = {} } = getters.getFinancingSettings(financingStateKey);
        const {
          advanceRent: settingsAdvanceRentPercent,
          leasePeriod: settingsLeasePeriod,
          annualMileage: settingsAnnualMileage,
        } = leasingSettings;
        advanceRentPercent = settingsAdvanceRentPercent;
        leasePeriodYears = settingsLeasePeriod;

        mileageObj = annualMileagesData
          .find((item) => item.mileage === settingsAnnualMileage) || {};
      }

      const { mileage: annualMileage } = mileageObj;

      const { residualValues = [] } = mileageObj;
      const activeResidualValue = residualValues
        .find((residualValue) => residualValue.leasePeriod === leasePeriodYears) || {};

      const residualValuePercent = roundNumber(activeResidualValue.valuePercent, 2) || 0;

      return {
        [LEASING_KEYS.advanceRentPercent]: roundNumber(advanceRentPercent, 10),
        [LEASING_KEYS.annualMileage]: roundNumber(annualMileage),
        [LEASING_KEYS.leasePeriod]: roundNumber(leasePeriodYears),
        [LEASING_KEYS.residualValuePercent]: roundNumber(residualValuePercent, 2),
        [LEASING_KEYS.nominalInterestRate]: roundNumber(nominalInterestRate, 2),
        [LEASING_KEYS.establishmentFee]: roundNumber(establishmentFee),
        [LEASING_KEYS.perTermFee]: roundNumber(perTermFee),
      };
    },

    getLeasingValues: () => (leasingDataParams = createLeasingDataParams(), roundNumbers = true) => {
      const numberFormatter = (number) => (roundNumbers ? roundNumber(number) : number);

      const leasingData = {
        ...createLeasingDataParams(),
        ...leasingDataParams,
      };

      const {
        items,
        advanceRentPercent,
        nominalInterestRate,
        leasePeriodYears,
        annualMileage,
        establishmentFee,
        perTermFee,
        electricProportionateVatRate,
        useVatCorrection,
      } = leasingData;

      let cashPrice = 0;
      let monthlyInstallmentRegular = 0;
      let monthlyInstallmentItemsSubjectToVat = 0;
      let monthlyInstallmentAnnualServices = 0;
      let residualValueTotal = 0;
      let monthlyInstallmentWithElectricVatResidualValues = 0;
      let electricVatResidualValueTotal = 0;
      let electricVatCorrectionPerMonth = 0;
      let electricVatPerMonth = 0;
      let monthlyInstallmentWithElectricVatProportionateVat = 0;
      let monthlyInstallmentWithElectricVatTotal = 0;
      let electricVatItem = null;
      let itemsSubjectToVatCashPriceTotal = 0;
      let itemsSubjectToVatResidualValueTotal = 0;
      let totalTaxes = 0;
      const electricProportionateVatMultiplier = 1 + electricProportionateVatRate;
      const electricVatAdjustedAdvanceRentPercent = advanceRentPercent / electricProportionateVatMultiplier;

      // @todo Fees should be increased with electricProportionateVatMultiplier but Applikator doesn't have support for that
      const electricVatPerTermFee = electricProportionateVatMultiplier > 1 ? perTermFee * 1.25 : perTermFee;
      const electricVatEstablishmentFee = electricProportionateVatMultiplier > 1 ? establishmentFee * 1.25 : establishmentFee;

      items.forEach((item) => {
        const itemPerTermFee = item.includeFees ? perTermFee : 0;
        const itemElectricPerTermFee = item.includeFees ? electricVatPerTermFee : 0;

        const sharedParams = {
          cashPrice: item.cashPrice,
          nominalInterestRate,
          leasePeriodYears,
          vatRatePercent: item.vatRatePercent,
        };

        if (item.isElectricVat) {
          // console.log('ELECTRIC VAT ITEMS', items);
          electricVatItem = item;

          // @todo OLD ELECTRIC VAT CALCULATION
          // const vatCorrectionFactor = VAT_CORRECTION_FACTORS_PER_YEAR[leasePeriodYears];

          // @todo This should use electricProportionateVatMultiplier but Applikator only has support for 25% VAT on the VAT Correction
          // electricVatCorrectionPerMonth = electricProportionateVatMultiplier * ((item.cashPrice * vatCorrectionFactor) / (leasePeriodYears * 12));

          // @todo OLD ELECTRIC VAT CALCULATION
          // electricVatCorrectionPerMonth = 1.25 * ((item.cashPrice * vatCorrectionFactor) / (leasePeriodYears * 12));
          // console.log('OLD ELECTRIC VAT CORRECTION PER MONTH', electricVatCorrectionPerMonth);
        } else if (item.isAnnualService) {
          monthlyInstallmentAnnualServices += calculateLeasingAnnualServiceMonthlyCost({
            ...sharedParams,
          });
        } else {
          cashPrice += item.cashPrice;
          totalTaxes += item.taxes;
          const originalListPrice = item.cashPrice + item.discountedValue;
          const isDiscounted = item.cashPrice !== originalListPrice;
          const isFullyDiscounted = item.cashPrice === 0 && isDiscounted;

          const itemSubjectToVat = item.vatRatePercent > 0;

          let { residualValuePercent } = item;

          if (isDiscounted && !item.calculateResidualValueWithDiscountedPrice) {
            const listPriceResidualValue = originalListPrice * (residualValuePercent / 100);

            residualValuePercent = isFullyDiscounted
              ? 0
              : (listPriceResidualValue / item.cashPrice) * 100;
          }

          const electricVatResidualValuePercent = itemSubjectToVat
            ? residualValuePercent
            : residualValuePercent * electricProportionateVatMultiplier;

          const itemResidualValue = item.cashPrice * (residualValuePercent / 100);

          const itemElectricVatResidualValue = (item.cashPrice - item.taxes) * (electricVatResidualValuePercent / 100)
            + (item.taxes * (residualValuePercent / 100));

          residualValueTotal += itemResidualValue;
          electricVatResidualValueTotal += itemElectricVatResidualValue;

          if (itemSubjectToVat) {
            itemsSubjectToVatCashPriceTotal += item.cashPrice;
            itemsSubjectToVatResidualValueTotal += itemResidualValue;
          }

          const monthlyCostParams = (residualValueParam, advanceRentPercentParam, perTermFeeParam) => ({
            ...sharedParams,
            // @todo Replaced with calculated residualValue, keep it that way?
            // residualValuePercent: residualValuePercentParam,
            taxes: item.taxes,
            perTermFee: perTermFeeParam,
            advanceRentPercent: advanceRentPercentParam,
            useVatCorrection,
            residualValue: residualValueParam,
          });

          if (itemSubjectToVat) {
            monthlyInstallmentItemsSubjectToVat += calculateLeasingMonthlyCost({
              ...monthlyCostParams(itemResidualValue, advanceRentPercent),
            });
          } else {
            monthlyInstallmentRegular += calculateLeasingMonthlyCost({
              ...monthlyCostParams(itemResidualValue, advanceRentPercent, itemPerTermFee),
            });
            monthlyInstallmentWithElectricVatResidualValues += calculateLeasingMonthlyCost({
              ...monthlyCostParams(itemResidualValue, electricVatAdjustedAdvanceRentPercent, itemElectricPerTermFee),
            });
          }
        }
      });

      if (electricVatItem) {
        const electricVatTotalCashPrice = (cashPrice - itemsSubjectToVatCashPriceTotal - totalTaxes) * electricProportionateVatMultiplier + totalTaxes;
        const electricVatTotalResidualValue = residualValueTotal - itemsSubjectToVatResidualValueTotal;
        const electricVatResidualValuePercentRate = electricVatTotalResidualValue / electricVatTotalCashPrice;
        const leasingElectricVat = electricVatItem.cashPrice * electricVatResidualValuePercentRate;
        electricVatCorrectionPerMonth = (leasingElectricVat / (leasePeriodYears * 12)) * 1.25;
        // @todo ELECTRIC VAT CORRECTION 2024 Keep until verified
        consoleLog('-------- ELECTRIC VAT CORRECTION CALC START ---------');
        consoleLog('electricProportionateVatMultiplier', electricProportionateVatMultiplier);
        consoleLog('cashPrice', cashPrice);
        consoleLog('residualValueTotal', residualValueTotal);
        consoleLog('totalTaxes', totalTaxes);
        consoleLog('electricVatTotalCashPrice', electricVatTotalCashPrice);
        consoleLog('electricVatTotalResidualValue', electricVatTotalResidualValue);
        consoleLog('electricVatResidualValuePercentRate', electricVatResidualValuePercentRate);
        consoleLog('TOTAL ELECTRIC VAT', electricVatItem.cashPrice);
        consoleLog('leasingElectricVat', leasingElectricVat);
        consoleLog('electricVatCorrectionPerMonth', electricVatCorrectionPerMonth);
        consoleLog('-------- ELECTRIC VAT CORRECTION CALC END ---------');
      }

      monthlyInstallmentWithElectricVatProportionateVat = (monthlyInstallmentWithElectricVatResidualValues - electricVatPerTermFee) * electricProportionateVatRate;

      monthlyInstallmentWithElectricVatTotal = monthlyInstallmentWithElectricVatResidualValues
        + monthlyInstallmentWithElectricVatProportionateVat
        + electricVatCorrectionPerMonth
        + monthlyInstallmentItemsSubjectToVat
        + monthlyInstallmentAnnualServices;

      monthlyInstallmentRegular += monthlyInstallmentAnnualServices + monthlyInstallmentItemsSubjectToVat;

      electricVatPerMonth = monthlyInstallmentWithElectricVatTotal - monthlyInstallmentRegular;

      monthlyInstallmentRegular = numberFormatter(monthlyInstallmentRegular);
      monthlyInstallmentWithElectricVatTotal = numberFormatter(monthlyInstallmentWithElectricVatTotal);

      const advanceRent = numberFormatter((cashPrice) * (advanceRentPercent / 100));

      const calculateTotalCost = (costPerMonth, establishmentFeeParam) => advanceRent + establishmentFeeParam
          + (costPerMonth * leasePeriodYears * 12);

      const totalCost = calculateTotalCost(monthlyInstallmentRegular, establishmentFee);
      const totalCostElectricVat = calculateTotalCost(monthlyInstallmentWithElectricVatTotal, electricVatEstablishmentFee);

      // @todo ELECTRIC VAT CORRECTION 2024 Keep until verified
      if (cashPrice === 690000) {
        consoleLog('--------- FINAL LEASING MONTHLY INSTALLMENT START ---------');
        consoleLog('CASH PRICE', cashPrice);
        consoleLog('VAT CORRECTION PER MONTH', electricVatCorrectionPerMonth);
        consoleLog('MONTHLY INSTALLMENT: REGULAR', monthlyInstallmentRegular);
        consoleLog('MONTHLY INSTALLMENT: ELECTRIC VAT', monthlyInstallmentWithElectricVatTotal);
        consoleLog('MONTHLY INSTALLMENT: DIFFERENCE', monthlyInstallmentWithElectricVatTotal - monthlyInstallmentRegular);
        consoleLog('monthlyInstallmentWithElectricVatTotal PARAMS START:');
        consoleLog('monthlyInstallmentWithElectricVatResidualValues', monthlyInstallmentWithElectricVatResidualValues);
        consoleLog('electricVatPerTermFee', electricVatPerTermFee);
        consoleLog('electricProportionateVatRate', electricProportionateVatRate);
        consoleLog('monthlyInstallmentWithElectricVatProportionateVat', monthlyInstallmentWithElectricVatProportionateVat);
        consoleLog('electricVatCorrectionPerMonth', electricVatCorrectionPerMonth);
        consoleLog('monthlyInstallmentItemsSubjectToVat', monthlyInstallmentItemsSubjectToVat);
        consoleLog('monthlyInstallmentAnnualServices', monthlyInstallmentAnnualServices);
        consoleLog('monthlyInstallmentWithElectricVatTotal PARAMS END:');
        consoleLog('--------- FINAL LEASING MONTHLY INSTALLMENT END ---------');
      }

      return {
        [LEASING_KEYS.advanceRent]: advanceRent,
        [LEASING_KEYS.advanceRentPercent]: advanceRentPercent,
        [LEASING_KEYS.advanceRentEstablishmentFee]: numberFormatter(advanceRent + establishmentFee),
        [LEASING_KEYS.annualMileage]: annualMileage,
        [LEASING_KEYS.cashPrice]: cashPrice,
        [LEASING_KEYS.establishmentFee]: establishmentFee,
        [LEASING_KEYS.perTermFee]: perTermFee,
        [LEASING_KEYS.leasePeriod]: leasePeriodYears,
        [LEASING_KEYS.nominalInterestRate]: nominalInterestRate,
        [LEASING_KEYS.totalMileage]: annualMileage * leasePeriodYears,
        [LEASING_KEYS.monthlyInstallment]: monthlyInstallmentWithElectricVatTotal,
        [LEASING_KEYS.totalCost]: numberFormatter(totalCostElectricVat),
        [LEASING_KEYS.residualValueTotal]: residualValueTotal,
        tempElectricVatAdjustedResidualValueTotal: electricVatResidualValueTotal,
        [LEASING_KEYS.electricVatPerMonth]: electricVatPerMonth,
        // @todo Old values, do something with them?
        // [LEASING_KEYS.monthlyInstallment]: monthlyInstallment,
        // [LEASING_KEYS.totalCost]: numberFormatter(totalCost),
        // [LEASING_KEYS.residualValueTotal]: residualValueTotal,
        // @todo Temp keeping it here...
        tempOldTotalCostWithoutElectricVat: totalCost,
        tempOldResidualValueTotalWithoutElectricVat: residualValueTotal,
      };
    },

    getLeasingDisclaimer: (state, getters) => ({
      leasingDataParams = createLeasingDataParams(),
      rawDisclaimer = null,
    } = {}) => {
      const leasingValues = getters.getLeasingValues(leasingDataParams);
      const suffixes = getters.getFinancingSuffixes;

      return parseLeasingVariables({
        text: rawDisclaimer,
        leasingValues,
        suffixes,
      });
    },

    getLoanValues: (state, getters) => ({
      financingStateKey = getters.getModelSelectedMarketModelId(),
      cashPrice = 0,
      financingConfig = getters.getConfigByKeyString('financing.config'),
      includeFees = true,
      useDefaultFinancingSettings = false,
    } = {}) => {
      const loanConfig = financingConfig?.[FINANCING_OPTIONS.loan] ?? {};

      let {
        defaultDownPayment: downPaymentPercent = 0,
        defaultPaymentPeriod: paymentPeriod = 0,
      } = loanConfig;

      let {
        establishmentFee = 0,
        propertyRegistrationFee = 0,
        perTermFee = 0,
        nominalInterestRate = 0,
      } = loanConfig;

      if (!useDefaultFinancingSettings) {
        const { loan: loanSettings = {} } = getters.getFinancingSettings(financingStateKey);
        const {
          downPayment: settingsDownPaymentPercent,
          paymentPeriod: settingsPaymentPeriod,
        } = loanSettings;
        downPaymentPercent = settingsDownPaymentPercent;
        paymentPeriod = settingsPaymentPeriod;
      }

      paymentPeriod = roundNumber(paymentPeriod);
      establishmentFee = includeFees ? roundNumber(establishmentFee) : 0;
      propertyRegistrationFee = includeFees ? roundNumber(propertyRegistrationFee) : 0;
      perTermFee = includeFees ? roundNumber(perTermFee) : 0;
      downPaymentPercent = roundNumber(downPaymentPercent, 10);
      nominalInterestRate = roundNumber(nominalInterestRate, 2);

      const monthlyPayments = paymentPeriod * 12;
      const downPayment = roundNumber(cashPrice * (downPaymentPercent / 100));
      const loanAmount = cashPrice - downPayment + establishmentFee + propertyRegistrationFee;
      const loanNeeds = loanAmount - establishmentFee - propertyRegistrationFee;

      const monthlyInstallment = calculateLoanMonthlyCost({
        cashPrice,
        downPaymentPercent,
        nominalInterestRate,
        paymentPeriodYears: paymentPeriod,
        establishmentFee,
        propertyRegistrationFee,
        perTermFee,
      });

      const totalLoanCost = monthlyInstallment * monthlyPayments;

      const totalCost = downPayment + totalLoanCost;

      const effectiveRate = calculateEffectiveRate({
        paymentPeriodYears: paymentPeriod,
        termsPerYear: 12,
        monthlyInstallment,
        loanAmount: loanNeeds,
        // Loan Amount should not include
        // loanAmount: loanAmount - establishmentFee,
        interestGuess: nominalInterestRate / 100,
      });

      return {
        [LOAN_KEYS.cashPrice]: cashPrice,
        [LOAN_KEYS.downPayment]: downPayment,
        [LOAN_KEYS.downPaymentPercent]: downPaymentPercent,
        [LOAN_KEYS.effectiveInterestRate]: effectiveRate,
        [LOAN_KEYS.establishmentFee]: establishmentFee,
        [LOAN_KEYS.loanAmount]: loanAmount,
        [LOAN_KEYS.loanNeeds]: loanNeeds,
        [LOAN_KEYS.loanPeriod]: paymentPeriod,
        [LOAN_KEYS.monthlyInstallment]: monthlyInstallment,
        [LOAN_KEYS.nominalInterestRate]: nominalInterestRate,
        [LOAN_KEYS.perTermFee]: perTermFee,
        [LOAN_KEYS.propertyRegistrationFee]: propertyRegistrationFee,
        [LOAN_KEYS.totalCost]: totalCost,
        [LOAN_KEYS.totalLoanCost]: totalLoanCost,
      };
    },

    getLoanDisclaimer: (state, getters) => ({
      financingStateKey = getters.getModelSelectedMarketModelId(),
      rawDisclaimer = null,
      cashPrice = 0,
      financingConfig = getters.getConfigByKeyString('financing.config'),
      includeFees = true,
      useDefaultFinancingSettings = false,
    } = {}) => {
      const loanValues = getters.getLoanValues({
        financingStateKey,
        cashPrice,
        financingConfig,
        includeFees,
        useDefaultFinancingSettings,
      });
      const suffixes = getters.getFinancingSuffixes;

      return parseLoanVariables({
        text: rawDisclaimer,
        loanValues,
        suffixes,
      });
    },

    getAutoSelectedOptionsData: (state, getters) => ({
      financingStateKey = getters.getModelSelectedMarketModelId(),
    } = {}) => getters.getFinancingState(financingStateKey)?.autoSelectedOptionsData,

  },
  mutations: {
    UPDATE_FINANCING_STATE(state, {
      financingStateKey,
      financingState = INITIAL_FINANCING_STATE_ITEM(),
    } = {}) {
      if (!financingStateKey) return;
      this._vm.$set(state.financingStates, financingStateKey, financingState);
    },

    UPDATE_SELECTED_FINANCING_OPTION(state, {
      financingStateKey,
      financingOptionType = state.financingStates?.[financingStateKey]?.availableFinancingOptions?.[0]?.type ?? '',
    } = {}) {
      this._vm.$set(state.financingStates[financingStateKey], 'selectedFinancingOption', financingOptionType);
    },

    UPDATE_FINANCING_CUSTOMER_TYPE(state, {
      financingStateKey,
      customerType = CUSTOMER_TYPES.consumer,
    } = {}) {
      this._vm.$set(state.financingStates[financingStateKey], 'selectedCustomerType', customerType);
    },

    UPDATE_AVAILABLE_FINANCING_OPTIONS(state, {
      financingStateKey,
      financingOptions = DEFAULT_FINANCING_OPTIONS,
    } = {}) {
      if (!Array.isArray(financingOptions)) return;
      this._vm.$set(state.financingStates[financingStateKey], 'availableFinancingOptions', financingOptions);
    },

    UPDATE_FINANCING_SETTING(state, {
      financingStateKey,
      financingOptionType,
      settingKey,
      value,
    } = {}) {
      if (!state.financingStates[financingStateKey]) return;

      this._vm.$set(
        state.financingStates[financingStateKey].financingSettings[financingOptionType],
        settingKey,
        value,
      );
    },

    UPDATE_FINANCING_SETTINGS_CALCULATING(state, {
      financingStateKey,
      isCalculating = false,
    } = {}) {
      if (!state.financingStates[financingStateKey]) return;

      this._vm.$set(
        state.financingStates[financingStateKey].financingSettings,
        'calculating',
        isCalculating,
      );
    },

    UPDATE_AUTO_SELECTED_OPTIONS_DATA(state, {
      financingStateKey,
      autoSelectedOptionsData = INITIAL_AUTO_SELECTED_OPTIONS_DATA(),
    } = {}) {
      if (!financingStateKey) return;
      if (!Array.isArray(autoSelectedOptionsData?.items)) return;

      this._vm.$set(state.financingStates[financingStateKey], 'autoSelectedOptionsData', autoSelectedOptionsData);
    },

  },
  actions: {
    async initFinancingState({ state, commit }, financingStateKey) {
      if (state.financingStates[financingStateKey]) return;
      commit('UPDATE_FINANCING_STATE', { financingStateKey });
    },

    async setSelectedFinancingOption({
      getters, commit, dispatch,
    }, {
      financingStateKey = getters.getActiveModelId,
      financingOptionType = getters.getAvailableFinancingOptions({
        financingStateKey,
        allowCashClones: true,
      })?.[0]?.type,
      alertError = true,
      updateConfiguratorKey = true,
      force = false,
    } = {}) {
      const option = String(financingOptionType).toLowerCase();

      const currentOption = getters.getSelectedFinancingOption({
        financingStateKey,
        allowCashClones: true,
      });

      if (option === currentOption && !force) return;

      if (
        !getters.isValidFinancingOption({ financingStateKey, financingOptionType: option })
      ) {
        if (!alertError) return;

        dispatch('setAlert', {
          key: 'invalidFinancingOptionError',
          message: 'error.invalidFinancingOption',
          log: `${option} is not a valid payment method`,
        });
        return;
      }

      let stepsArray = [...getters.getModelConfigSteps(financingStateKey)];

      stepsArray = await dispatch('removeFinancingOptionExtraSteps', {
        financingStateKey,
        financingOptionType: currentOption,
        steps: stepsArray,
      });

      commit('UPDATE_SELECTED_FINANCING_OPTION', { financingStateKey, financingOptionType: option });

      stepsArray = await dispatch('addFinancingOptionExtraSteps', {
        financingStateKey,
        financingOptionType: option,
        steps: stepsArray,
      });

      await dispatch('checkAndSetModelConfigSteps', { modelId: financingStateKey, steps: stepsArray });

      await dispatch('checkAndSelectOptionsRequiredForFinancingOption', { financingOptionType: option, updateConfiguratorKey: false });

      if (updateConfiguratorKey) await dispatch('setConfiguratorKeyParam');
    },

    async checkAndSelectOptionsRequiredForFinancingOption({ getters, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({ financingStateKey: marketModelId }),
      updateConfiguratorKey = false,
      updateAutoSelectedOptionsData = true,
    } = {}) {
      if (!marketModelId) return;

      const options = getters.getMarketModelOptions({ marketModelId });

      if (!options?.length) return;

      const selectPromises = [];
      const autoSelectedIds = [];

      options.forEach((option) => {
        if (getters.isOptionSelected({ marketModelId, marketModelOptionId: option.id })) return;
        if (!getters.isOptionRequiredForFinancingOption({ marketModelId, marketModelOptionId: option.id })) return;

        autoSelectedIds.push(option.id);

        selectPromises.push(dispatch('selectMarketModelOption', {
          marketModelId,
          marketModelOptionId: option.id,
          updateConfiguratorKey,
        }));
      });

      if (updateAutoSelectedOptionsData) {
        await dispatch('setAutoSelectedOptionsData', {
          financingStateKey: marketModelId,
          autoSelectedOptionsData: {
            financingOptionType,
            marketModelId,
            items: autoSelectedIds,
          },
        });
      }

      await Promise.allSettled(selectPromises);
    },

    async setAutoSelectedOptionsData({ getters, commit }, {
      financingStateKey = getters.getModelSelectedMarketModelId(),
      autoSelectedOptionsData = INITIAL_AUTO_SELECTED_OPTIONS_DATA(),
    } = {}) {
      const data = {
        ...INITIAL_AUTO_SELECTED_OPTIONS_DATA(),
        ...autoSelectedOptionsData,
      };

      if (!data.financingOptionType) return;
      if (!Array.isArray(data.items)) return;
      if (!data.items.length) return;
      if (!data.marketModelId) return;

      commit('UPDATE_AUTO_SELECTED_OPTIONS_DATA', {
        financingStateKey,
        autoSelectedOptionsData: data,
      });
    },

    async resetAutoSelectedOptionsData({ getters, commit }, {
      financingStateKey = getters.getModelSelectedMarketModelId(),
    } = {}) {
      commit('UPDATE_AUTO_SELECTED_OPTIONS_DATA', { financingStateKey });
    },

    async setFinancingCustomerType({
      getters, commit, dispatch,
    }, {
      financingStateKey = getters.getActiveModelId,
      customerType = '',
      updateConfiguratorKey = true,
    } = {}) {
      if (getters.getFinancingCustomerType(financingStateKey) === customerType) return;

      const validCustomerTypes = getters.getAvailableFinancingCustomerTypes(financingStateKey);

      if (!validCustomerTypes) {
        throw new Error('No valid customer types');
      }

      const isValid = validCustomerTypes.includes(customerType);

      let typeToSelect;

      if (isValid) {
        // if customerType is set and valid, then lets use that one
        typeToSelect = customerType;
      } else {
        const commercialVehicle = getters.getModelById(financingStateKey)?.commercialVehicle;
        // If model.commercialVehicle is true, and company is a valid customer type, then select that one
        if (commercialVehicle && validCustomerTypes.includes(CUSTOMER_TYPES.company)) {
          typeToSelect = CUSTOMER_TYPES.company;
        } else {
          // fallback to first available customer type
          typeToSelect = validCustomerTypes?.[0];
        }
      }

      await dispatch('removeFinancingOptionExtraSteps', {
        financingStateKey,
      });

      commit('UPDATE_FINANCING_CUSTOMER_TYPE', { financingStateKey, customerType: typeToSelect });
      if (updateConfiguratorKey) await dispatch('setConfiguratorKeyParam');

      await dispatch('setSelectedFinancingOption', { financingStateKey, updateConfiguratorKey, force: true });
    },

    async setAvailableFinancingOptions({
      getters, commit, dispatch,
    }, {
      financingStateKey = getters.getActiveModelId,
      financingOptions = DEFAULT_FINANCING_OPTIONS,
    } = {}) {
      const invalidFinancingOptions = (reason = '') => {
        commit('UPDATE_AVAILABLE_FINANCING_OPTIONS', { financingStateKey });
        dispatch('setSelectedFinancingOption', { financingStateKey });
        consoleLog(`Invalid financing options: ${reason}`);
      };

      if (!Array.isArray(financingOptions)) {
        invalidFinancingOptions('Must be an array');
        return;
      }

      const validFinancingOptions = financingOptions.filter(
        (option) => !!DEFAULT_FINANCING_OPTIONS.find((supported) => option.type === supported.type),
      );

      if (!validFinancingOptions.length) {
        invalidFinancingOptions('No supported options');
        return;
      }

      commit('UPDATE_AVAILABLE_FINANCING_OPTIONS', { financingStateKey, financingOptions: validFinancingOptions });
      if (
        !getters.isValidFinancingOption({
          financingStateKey,
          financingOptionType: getters.getSelectedFinancingOption({ financingStateKey }),
        })
      ) await dispatch('setSelectedFinancingOption', { financingStateKey, updateConfiguratorKey: false, alertError: false });
    },

    async initFinancingSettings({
      getters, commit, dispatch,
    }, {
      financingStateKey = getters.getModelSelectedMarketModelId(),
      financingConfig = getters.getConfigByKeyString('financing.config'),
      force = false,
    } = {}) {
      try {
        if (!financingConfig.loan || !financingConfig.leasing) return;

        const {
          downPayment: currentDownPayment,
          paymentPeriod: currentPaymentPeriod,
        } = getters.getFinancingSettings(financingStateKey)?.loan ?? {};
        const {
          annualMileage: currentAnnualMileage,
          advanceRent: currentAdvanceRent,
          leasePeriod: currentLeasePeriod,
        } = getters.getFinancingSettings(financingStateKey)?.leasing ?? {};

        const isInitiated = !!currentDownPayment
          && !!currentPaymentPeriod
          && !!currentAnnualMileage
          && !!currentAdvanceRent
          && !!currentLeasePeriod;

        if (isInitiated && !force) return;

        const leasingValues = getters.getLeasingSettings({
          financingStateKey,
          financingConfig,
          useDefaultFinancingSettings: true,
        });

        const loanValues = getters.getLoanValues({
          financingStateKey,
          financingConfig,
          useDefaultFinancingSettings: true,
        });

        const settings = {
          loan: {
            downPayment: loanValues.DOWN_PAYMENT_PERCENT,
            paymentPeriod: loanValues.LOAN_PERIOD,
          },
          leasing: {
            annualMileage: leasingValues.ANNUAL_MILEAGE,
            advanceRent: leasingValues.ADVANCE_RENT_PERCENT,
            leasePeriod: leasingValues.LEASE_PERIOD,
          },
        };

        Object.keys(settings).forEach((financingOptionType) => {
          Object.keys(settings[financingOptionType]).forEach((settingKey) => {
            commit('UPDATE_FINANCING_SETTING', {
              financingStateKey,
              financingOptionType,
              settingKey,
              value: settings[financingOptionType][settingKey],
            });
          });
        });

        setTimeout(async () => {
          await dispatch('setFinancingSettingsCalculating', { financingStateKey, isCalculating: false });
        }, 0);
      } catch (error) {
        dispatch('setAlert', { key: 'errorInitFinancingSettings', message: 'error.initFinancingSettings', log: error });
        throw error;
      }
    },

    async setFinancingSetting({ getters, commit, dispatch }, {
      financingStateKey = getters.getModelSelectedMarketModelId(),
      financingOptionType,
      settingKey,
      value,
      updateConfiguratorKey = true,
    } = {}) {
      commit('UPDATE_FINANCING_SETTING', {
        financingStateKey, financingOptionType, settingKey, value,
      });
      await dispatch('setFinancingSettingsCalculating', { financingStateKey, isCalculating: false });
      if (updateConfiguratorKey) await dispatch('setConfiguratorKeyParam');
    },

    async setFinancingSettingsCalculating({ getters, commit }, {
      financingStateKey = getters.getModelSelectedMarketModelId(),
      isCalculating = false,
    }) {
      if (getters.isFinancingSettingsCalculating(financingStateKey) !== isCalculating) {
        commit('UPDATE_FINANCING_SETTINGS_CALCULATING', { financingStateKey, isCalculating });
      }
    },

    async addFinancingOptionExtraSteps({ getters, dispatch }, {
      financingStateKey = getters.getActiveModelId,
      financingOptionType = getters.getSelectedFinancingOption({ financingStateKey }),
      steps,
    } = {}) {
      const extraSteps = getters.getFinancingOptionExtraSteps({
        financingStateKey,
        financingOptionType,
      });
      let stepsArray = steps;

      const extraStepsPromises = extraSteps.map(async (step) => {
        stepsArray = await dispatch('addModelConfigStep', { modelId: financingStateKey, stepKey: step.key, steps: stepsArray });
      });

      await Promise.all(extraStepsPromises);
      return stepsArray;
    },

    async removeFinancingOptionExtraSteps({ getters, dispatch }, {
      financingStateKey = getters.getActiveModelId,
      financingOptionType = getters.getSelectedFinancingOption({ financingStateKey }),
      steps,
    } = {}) {
      const extraSteps = getters.getFinancingOptionExtraSteps({
        financingStateKey,
        financingOptionType,
      });
      let stepsArray = steps;

      const extraStepsPromises = extraSteps.map(async (step) => {
        stepsArray = await dispatch('removeModelConfigStep', { modelId: financingStateKey, stepKey: step.key, steps: stepsArray });
      });

      await Promise.all(extraStepsPromises);
      return stepsArray;
    },

  },
};

export default FinancingStore;
